export default {
  title: 'Finalisez votre inscription',
  content:
    'Cliquez sur le bouton ci-dessous afin de procéder à la finalisation de votre inscription.',
  button: 'Je confirme',
  icon: 'account_circle',
  success: {
    icon: 'account_circle',
    title: "Finalisez votre inscription",
    content:
      '<span class="text-bold"> Un e-mail vient de vous être envoyé.</span><br>Pour finaliser votre inscription, rendez-vous dans votre boite e-mail et cliquez sur le lien. <br>',
    button: {
      text: "J'ai compris",
      route: 'Home',
    },
  },
  error: {
    icon: 'account_circle',
    title: '',
    content: '',
    button: {
      text: '',
      route: '',
    },
  },
};
